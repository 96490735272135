<template>

    <v-container>

        <v-card>
            <v-card-title class="pb-0 primary--text">Station Parameter Registration Form</v-card-title>
            <v-form class="main-form-card">
                <v-container>
                    
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        >
                            <v-subheader class="pa-1 primary--text">
                                <v-icon style="color:#0199be;" class="mr-2">mdi-text-box-search-outline</v-icon>
                                Search in List Parameter
                            </v-subheader>
                            <v-text-field
                            v-model="searchParam"
                            type="text"
                            placeholder="Search"
                            outlined
                            dense
                            autocomplete="off"
                            @input="getTextSearch($event)"
                            ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        >
                            <v-subheader class="pa-1 primary--text">
                                <v-icon style="color:#0199be;" class="mr-2">mdi-store-24-hour</v-icon>
                                Station
                            </v-subheader>
                            <v-select
                            v-on:change="selectValueStation"
                            :items="itemStation"
                            label="Station"
                            required
                            outlined 
                            dense
                            ></v-select>
                        </v-col>
                    </v-row>
        
                    <v-row>

                        <!-- Column List Checkbox Parameter -->
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-subheader class="pa-1 primary--text">
                            <v-icon style="color:#0199be;" class="mr-2">mdi-water</v-icon>
                            List Parameter
                        </v-subheader>
                            <v-card
                            :loading="loadingLeft"
                            height="400px"
                            >
                                <v-form>
                                    <v-container height="400px" class="scroll">


                                        <div class="item-param" v-for="item in checkboxParam" :key="item.value">

                                            <input
                                            :id="item.value+'_ACTIVE'"
                                            class="input-checkbox"
                                            :value="[item.text,item.value]"
                                            type="checkbox" 
                                            v-model="arrayParamChecked" 
                                            @change="checkedValueParam($event,item.text,item.value)">
                                            <span style="padding-left:20px;">{{item.text}}</span>

                                            <!-- <input
                                            v-if="!item.value+'_ACTIVE' || !nodes.length"
                                            :value="[item.text,item.value]"
                                            type="checkbox" 
                                            v-model="arrayParamChecked" 
                                            @change="checkedValueParam($event,item.text,item.value)">
                                            <span style="padding-left:20px;">{{item.text}}</span> -->

                                        </div>

                                        
                                    </v-container>
                                </v-form>
                            </v-card>
                        </v-col>

                        <!-- Column List Selected Parameter -->
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-subheader class="pa-1 primary--text">
                            <v-icon style="color:#0199be;" class="mr-2">mdi-water-plus</v-icon>
                            Parameter For Station {{valStation}}
                        </v-subheader>
                            <v-card
                            :loading="loadingRight"
                            height="400px"
                            >
                                <v-form>
                                    <v-container height="400px" class="scroll">
                                        
                                        <span class="black--text" style="font-size:0.9em;font-weight:bold;">
                                            * Click on parameter below to remove parameter from station
                                        </span>

                                        <div class="item-param-selected" v-for="item in arrayParamChecked" :key="item.value">
                                            
                                            <span @click="removeParamThis(item[0],item[1])">
                                                <v-icon style="color:#0199be;">mdi-water</v-icon>
                                                <span style="padding-left:10px;margin:5px;">{{item[0]}}</span>  
                                            </span>

                                            <!-- <v-text-field
                                            class="min-value value-after-selected"
                                            type="text"
                                            placeholder="Minimum"
                                            outlined
                                            dense
                                            small
                                            @input="getMinumumValue($event,item)"
                                            @keypress="isNumber($event)"
                                            ></v-text-field>

                                            <v-text-field
                                            class="max-value value-after-selected"
                                            type="text"
                                            placeholder="Maximum"
                                            outlined
                                            dense
                                            small
                                            @input="getMaximumValue($event,item)"
                                            @keypress="isNumber($event)"
                                            ></v-text-field> -->

                                            
                                            
                                            
                                        </div>

                                        
                                    </v-container>
                                </v-form>
                            </v-card>
                        </v-col>


                    </v-row>

                    <v-row>
                        <v-col>
                            <v-btn
                            @click="postParam"
                            class="primary"
                            >
                                Set
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-container>
            </v-form>
        </v-card>

        <!-- ------------------------------------ -->

        <!-- Popups/Dialogs Success Param Added-->
        <div class="text-center">
            <v-dialog
            v-model="dialogSuccessParamAdded"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 success white--text">
                Success! <v-icon style="color:white;margin-left:15px;">mdi-water-check</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                Parameters successfully added.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="success--text"
                    text
                    @click="dialogSuccessParamAdded = false;reloadThis()"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>

        <!-- Popups/Dialogs Error Station Null-->
        <div class="text-center">
            <v-dialog
            v-model="dialogErrorStation"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 error white--text">
                Error! <v-icon style="color:white;margin-left:15px;">mdi-alert-circle</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                Something when wrong. Please select station first and make sure your internet is connected before add paramater.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="error--text"
                    text
                    @click="dialogErrorStation = false"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>


        

        
    </v-container>
</template>

<script>
import axios from 'axios';
import ForgotPasswordVue from '../../User/Login/ForgotPassword.vue';

export default {
  data: () => ({

      APIStationDataPost: [],
      stationDataPost: [],

      searchParam: "",
      itemStation: [],
      valStation : null,

      listAllParams: [],
      listAllParamsSearch: [],
      checkboxParam: [],
      listDetailsParamActive: [],
      arrayParamChecked: [],

      listFullNameParamSelected: [],

      minValueParam: [],

      successDialogParamAdded: false,
      paramDataPost: [],

      checkStatusAdd: null,

      dialogSuccessParamAdded: false,
      dialogErrorStation: false,

      loadingLeft: false,
      loadingRight: false,
        
  }),

  methods: {

        removeitemArray(arr) {
            var what, a = arguments, L = a.length, ax;
            while (L > 1 && arr.length) {
                what = a[--L];
                while ((ax= arr.indexOf(what)) !== -1) {
                    arr.splice(ax, 1);
                }
            }
            return arr;
        },

        load(){

            this.loadAllStation();
            this.loadAllListParameter();

        },

        reloadThis(){
            window.location.reload()
        },

        loadAllStation(){

            this.stationDataPost = [];
            this.itemStation = [];

            axios.get('https://apis.spatialworks.com.my/sarawak/cms/stations', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                console.log(response.data);

                // this.stationDataPost = result;
                this.stationDataPost = response.data;
                this.APIStationDataPost = response.data;
                // console.log(this.stationDataPost);

                

                for (let i = 0; i < response.data.length; i++) {
                    this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
                }
                
            })
            .catch(error => {
                console.log(error);
            })
        },

        loadAllListParameter(){
            axios.get('https://apis.spatialworks.com.my/sarawak/cms/params', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                //   response.data.forEach(function(res){
                //       this.checkboxParam = res["text"];
                //   });

                // for (let i = 0; i < response.data.length; i++) {
                //     if (response.data[i].value != "WQI" && response.data[i].value != "SI_DO_SAT"
                //     && response.data[i].value != "DO_CON" && response.data[i].value != "DO_SAT"
                //     && response.data[i].value != "SI_BOD" && response.data[i].value != "BOD"
                //     && response.data[i].value != "SI_COD" && response.data[i].value != "COD"
                //     && response.data[i].value != "SI_NH3N" && response.data[i].value != "NH3N"
                //     && response.data[i].value != "SI_TSS" && response.data[i].value != "TSS"
                //     && response.data[i].value != "SI_pH" && response.data[i].value != "pH") {
                //         this.listAllParams.push(response.data[i])
                //     }
                // }

                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].value != "WQI" && response.data[i].value != "SI_DO_SAT"
                    && response.data[i].value != "SI_BOD" && response.data[i].value != "SI_COD"
                    && response.data[i].value != "SI_NH3N" && response.data[i].value != "SI_TSS"
                    && response.data[i].value != "SI_pH") {
                        this.listAllParams.push(response.data[i])
                    }
                }

                // this.listAllParams = response.data;
                // this.checkboxParam = response.data;
                // console.log(this.listAllParams);
                
                
            })
            .catch(error => {
                console.log(error);
            })
        },

        getTextSearch: function(textSearch) {

            this.checkboxParam = this.listAllParamsSearch

            this.checkboxParam = this.checkboxParam.filter(item => {
                return item.text.toLowerCase().includes(textSearch);
            });

        },

        selectValueStation: function(e) {

            this.loadingLeft = true;
            this.loadingRight = true;

            this.listAllParamsSearch = [];
            this.checkboxParam = [];
            this.listDetailsParamActive = [];
            this.arrayParamChecked = [];
            this.paramDataPost = [];
            this.stationDataPost = [];
            // this.stationDataPost = this.APIStationDataPost;

            this.valStation = e.split(' - ')[0];
            // console.log(this.valStation);
            
            axios.get('https://apis.spatialworks.com.my/sarawak/cms/stations', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                this.stationDataPost = response.data;
            })
            .catch(error => {
                console.log(error);
            })

            // console.log(this.stationDataPost);
            // console.log(this.APIStationDataPost);
             
            let myInterval1 = setInterval(() => {

                    let stationData = [];
                    for (let i = 0; i < this.APIStationDataPost.length; i++) {
                        if (this.APIStationDataPost[i].stationId ==  this.valStation) {
                        stationData.push(this.APIStationDataPost[i]);
                        }
                    }
                    console.log(stationData);

                    let stationActive = [];
                    let stationInactive = [];

                    let keyData = Object.keys(stationData[0])
                    //  console.log(keyData);
                    for (let x = 0; x < keyData.length; x++) {
                    //  console.log(stationData[0][keyData[x]]+"_ACTIVE");
                        if (stationData[0][keyData[x]] == null || stationData[0][keyData[x]] == "") {
                            if (keyData[x].includes("ACTIVE")) {
                            //  console.log(keyData[x]);
                                stationInactive.push(keyData[x]);
                            }
                        // console.log(keyData[x]);
                        }
                        else if(stationData[0][keyData[x]] != null || stationData[0][keyData[x]] != ""){
                            if (keyData[x].includes("ACTIVE")) {
                                //  console.log(keyData[x]);
                                stationActive.push(keyData[x]);
                            }
                        }
                    }

                    //  console.log(stationActive);
                    //  console.log(this.listAllParams);
                    //  console.log(stationInactive);
                    //  console.log(this.listAllParams);


                    // List Param Belah Kiri
                    //  for (let k = 0; k < this.listAllParams.length; k++) {
                    //      for (let j = 0; j < stationInactive.length; j++) {
                    //         if (this.listAllParams[k].value+"_ACTIVE" == stationInactive[j]) {
                    //             this.checkboxParam.push(this.listAllParams[k])
                    //         }
                    //         // this.checkboxParam.push(this.listAllParams[k])
                    //      } 
                    //  }
                    this.checkboxParam = this.listAllParams;
                    this.listAllParamsSearch = this.checkboxParam;
                    //  console.log(this.checkboxParam);

                    // Listkan Param Belah Kanan
                    let storeJap = [];
                    for (let k = 0; k < this.listAllParams.length; k++) {
                        for (let j = 0; j < stationActive.length; j++) {
                            if (this.listAllParams[k].value+"_ACTIVE" === stationActive[j] || this.listAllParams[k].value+"_ACTIVE" === "_"+stationActive[j]) {
                                storeJap.push(this.listAllParams[k])
                                this.listDetailsParamActive.push(this.listAllParams[k])
                            }
                        } 
                    }
                    // console.log(this.listDetailsParamActive);
                    // console.log(storeJap);

                    for (let z = 0; z < storeJap.length; z++) {
                        this.arrayParamChecked.push([storeJap[z].text,storeJap[z].value]);
                    }

                    this.loadingLeft = false;
                    this.loadingRight = false;
                

                clearInterval(myInterval1);
            }, 1000)
             
            

        },

        checkedValueParam(evenCheck,texName,paramValue){
            // console.log(evenCheck,texName,paramValue);
            // console.log(evenCheck.target.checked);

            console.log(this.arrayParamChecked);

            this.paramDataPost.push({
                STATION_ID: null
            });

            this.paramDataPost.forEach(function(res){
                res[paramValue+'_MIN'] = null;
                res[paramValue+'_MAX'] = null;
            })

            
            let keyDataStationDataPost = Object.keys(this.stationDataPost[0])
            if (evenCheck.target.checked == true) {
                for (let lol = 0; lol < this.stationDataPost.length; lol++) {
                    if (this.stationDataPost[lol].stationId == this.valStation) {
                        // console.log(this.stationDataPost[lol]);
                        for (let i = 0; i < keyDataStationDataPost.length; i++) {
                            if (keyDataStationDataPost[i] == paramValue+"_ACTIVE") {
                                this.stationDataPost[lol][keyDataStationDataPost[i]] = paramValue;
                            }
                            else if ("_"+keyDataStationDataPost[i] == paramValue+"_ACTIVE") {
                                this.stationDataPost[lol][keyDataStationDataPost[i]] = paramValue.substr(1, 50); 
                            }
                        }
                        console.log(this.stationDataPost[lol]);
                        
                    }
                }
            }
            else if (evenCheck.target.checked == false) {
                for (let lol = 0; lol < this.stationDataPost.length; lol++) {
                    if (this.stationDataPost[lol].stationId == this.valStation) {
                        // console.log(this.stationDataPost[lol]);
                        for (let i = 0; i < keyDataStationDataPost.length; i++) {
                            if (keyDataStationDataPost[i] == paramValue+"_ACTIVE" || "_"+keyDataStationDataPost[i] == paramValue+"_ACTIVE") {
                                this.stationDataPost[lol][keyDataStationDataPost[i]] = null;
                            }
                        }
                        console.log(this.stationDataPost[lol]);
                    }
                }
            }
            

        },

        removeParamThis(param,value){

            console.log(param,value);

            for (let i = 0; i < this.arrayParamChecked.length; i++) {
                if (this.arrayParamChecked[i][0] == param) {
                    // this.arrayParamChecked.remove(this.arrayParamChecked[i]);
                    // console.log(this.arrayParamChecked[i]);
                    const index = this.arrayParamChecked.indexOf(this.arrayParamChecked[i]);
                    // console.log(index);
                    if (index > -1) {
                    this.arrayParamChecked.splice(index, 1); // 2nd parameter means remove one item only
                    }
                }
            }

            // this.checkedValueParam(param,value,false)
            let keyData = Object.keys(this.stationDataPost[0])
            for (let lol = 0; lol < this.stationDataPost.length; lol++) {
                if (this.stationDataPost[lol].stationId == this.valStation) {
                    // console.log(this.stationDataPost[lol]);
                    for (let i = 0; i < keyData.length; i++) {
                        if (keyData[i] == value+"_ACTIVE" || "_"+keyData[i] == value+"_ACTIVE") {
                            this.stationDataPost[lol][keyData[i]] = null;
                        }
                    }
                    console.log(this.stationDataPost[lol]);
                    
                }
            }

        },
    

        // isNumber: function(evt) {
        //     evt = (evt) ? evt : window.event;
        //     var charCode = (evt.which) ? evt.which : evt.keyCode;
        //     if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        //         evt.preventDefault();
        //     } else {
        //         return true;
        //     }
        // },

        // getMinumumValue: function(value,item) {

        //     let keyData = Object.keys(this.paramDataPost[0])
        //     // console.log(keyData);
        //     for (let i = 0; i < keyData.length; i++) {
        //         if (keyData[i] === item[1]+"_MIN") {
        //             this.paramDataPost[0][keyData[i]] = value;
        //         }
        //     }
            
        // },

        // getMaximumValue: function(value,item) {

        //     let keyData = Object.keys(this.paramDataPost[0])
        //     // console.log(keyData);
        //     for (let i = 0; i < keyData.length; i++) {
        //         if (keyData[i] === item[1]+"_MAX") {
        //             this.paramDataPost[0][keyData[i]] = value;
        //         }
        //     }
            
        // },

        postParam(){

            console.log();

            if (this.valStation != null) {
                // console.log("run post add param");

                let stationId = null;
                stationId = this.valStation;
                console.log(this.stationDataPost);

                // console.log(this.stationDataPost);
                let jsonDataEditStation = "";
                this.stationDataPost.forEach(function(res){
                    if (res["stationId"] == stationId) {
                        jsonDataEditStation = JSON.stringify(res);
                    }
                });
                // console.log(jsonDataEditStation);

                // API PUT EDIT STATION
                axios.put('https://apis.spatialworks.com.my/sarawak/cms/editstation',
                jsonDataEditStation, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.$store.getters.user.token,
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    this.checkStatusAdd = "success";
                    this.listAllParamsSearch = [];
                    this.checkboxParam = [];
                    this.listDetailsParamActive = [];
                    this.arrayParamChecked = [];
                    this.dialogSuccessParamAdded = true;   
                })
                .catch(error => {
                    this.checkStatusAdd = "failed"; 
                    console.log(error);
                })

                let myInterval2 = setInterval(() => {

                    
                    if (this.checkStatusAdd == "success") {
                        this.dialogSuccessParamAdded = true;
                    }
                    else if(this.checkStatusAdd == "failed"){
                        this.dialogErrorStation = true;
                    }
                    clearInterval(myInterval2);
                }, 1000)
                

            }
            else{
                console.log("make sure station is selected");
                this.dialogErrorStation = true;
            }

        }

  },

  computed: {
    
        // filterListParam(){
        //     if(this.searchParam){
        //         return this.checkboxParam.filter((item)=>{
        //             // return this.searchParam.toLowerCase().split(' ').every(v => item.fullname.toLowerCase().includes(v))
        //             return item.fullname.match(this.searchParam)
        //         })
        //     }
        //     else{
        //         return this.checkboxParam;
        //     }
        // }

  },

  mounted() {
      this.load();
  }
}
</script>

<style lang="scss">

    // MAIN CARD
    .main-form-card{
        padding: 20px;
    }

    // HEADER PART

    // CONTAINER LEFT
    .scroll {
        overflow-y: scroll;
        height: 400px;
        padding: 20px;
    }

    .item-param{
        padding:10px 0px;
        padding-left: 20px;
    }

    .item-param:hover{
        background: #e1f3f7;
        color: #35aecb;
    }

    .item-param > input{
        cursor: pointer;
    }

    .item-param-selected{
        display: flex;
        // background: #e1f3f7;
        background-image: linear-gradient(to right, #e1f3f7, #ffffff) ;
        margin: 5px 0px;
    }


    .item-param-selected:hover{
        display: flex;
        background: #caedf4;
        cursor: pointer;
    }

    .item-param-selected > span{
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        padding: 2px 12px;
    }

    .value-after-selected{
        margin: 0px 5px !important;
        padding: 5px !important;
    }

</style>